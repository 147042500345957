;(function() {

	"use strict";

	var root = this

	var has_require = typeof require !== 'undefined'

	var THREE = root.THREE || has_require && require('three')
	if( !THREE )
		throw new Error( 'MeshLine requires three.js' )

	function MeshLine() {

		this.positions = [];

		this.previous = [];
		this.next = [];
		this.side = [];
		this.width = [];
		this.indices_array = [];
		this.uvs = [];
		this.counters = [];
		this.geometry = new THREE.BufferGeometry();
		this.colors = [];
    this.displacement = [];
		this.widthCallback = null;

	}

	MeshLine.prototype.setGeometry = function( g, c ) {

		this.widthCallback = c;

		this.positions = [];
		this.counters = [];
		this.colors = [];
		this.displacement = [];
		if( g instanceof THREE.Geometry ) {
			for( var j = 0; j < g.vertices.length; j++ ) {
				var v = g.vertices[ j ];
				var c = j/g.vertices.length;
				this.positions.push( v.x, v.y, v.z );
				this.positions.push( v.x, v.y, v.z );
				this.counters.push(c);
				this.counters.push(c);
				if (g.displacement && g.displacement[j]) {
          this.displacement.push(g.displacement[j]);
          this.displacement.push(g.displacement[j]);
				}
				if (g.colors[j]) {
					this.colors.push(g.colors[j].r, g.colors[j].g, g.colors[j].b);
					this.colors.push(g.colors[j].r, g.colors[j].g, g.colors[j].b);
				}
			}
		}

		if( g instanceof THREE.BufferGeometry ) {
			// read attribute positions ?
		}

		if( g instanceof Float32Array || g instanceof Array ) {
			for( var j = 0; j < g.length; j += 3 ) {
				var c = j/g.length;
				this.positions.push( g[ j ], g[ j + 1 ], g[ j + 2 ] );
				this.positions.push( g[ j ], g[ j + 1 ], g[ j + 2 ] );
				this.counters.push(c);
				this.counters.push(c);
			}
		}

		this.process();

	}

	MeshLine.prototype.compareV3 = function( a, b ) {

		var aa = a * 6;
		var ab = b * 6;
		return ( this.positions[ aa ] === this.positions[ ab ] ) && ( this.positions[ aa + 1 ] === this.positions[ ab + 1 ] ) && ( this.positions[ aa + 2 ] === this.positions[ ab + 2 ] );

	}

	MeshLine.prototype.copyV3 = function( a ) {

		var aa = a * 6;
		return [ this.positions[ aa ], this.positions[ aa + 1 ], this.positions[ aa + 2 ] ];

	}

	MeshLine.prototype.process = function() {

		var l = this.positions.length / 6;

		this.previous = [];
		this.next = [];
		this.side = [];
		this.width = [];
		this.indices_array = [];
		this.uvs = [];

		for( var j = 0; j < l; j++ ) {
			this.side.push( 1 );
			this.side.push( -1 );
		}

		var w;
		for( var j = 0; j < l; j++ ) {
			if( this.widthCallback ) w = this.widthCallback( j / ( l -1 ) );
			else w = 1;
			this.width.push( w );
			this.width.push( w );
		}

		for( var j = 0; j < l; j++ ) {
			this.uvs.push( j / ( l - 1 ), 0 );
			this.uvs.push( j / ( l - 1 ), 1 );
		}

		var v;

		if( this.compareV3( 0, l - 1 ) ){
			v = this.copyV3( l - 2 );
		} else {
			v = this.copyV3( 0 );
		}
		this.previous.push( v[ 0 ], v[ 1 ], v[ 2 ] );
		this.previous.push( v[ 0 ], v[ 1 ], v[ 2 ] );
		for( var j = 0; j < l - 1; j++ ) {
			v = this.copyV3( j );
			this.previous.push( v[ 0 ], v[ 1 ], v[ 2 ] );
			this.previous.push( v[ 0 ], v[ 1 ], v[ 2 ] );
		}

		for( var j = 1; j < l; j++ ) {
			v = this.copyV3( j );
			this.next.push( v[ 0 ], v[ 1 ], v[ 2 ] );
			this.next.push( v[ 0 ], v[ 1 ], v[ 2 ] );
		}

		if( this.compareV3( l - 1, 0 ) ){
			v = this.copyV3( 1 );
		} else {
			v = this.copyV3( l - 1 );
		}
		this.next.push( v[ 0 ], v[ 1 ], v[ 2 ] );
		this.next.push( v[ 0 ], v[ 1 ], v[ 2 ] );

		for( var j = 0; j < l - 1; j++ ) {
			var n = j * 2;
			this.indices_array.push( n, n + 1, n + 2 );
			this.indices_array.push( n + 2, n + 1, n + 3 );
		}

		// console.log(this.positions.length, this.next.length, this.previous.length)

		if (!this.attributes) {
			this.attributes = {
				position: new THREE.BufferAttribute( new Float32Array( this.positions ), 3 ),
				previous: new THREE.BufferAttribute( new Float32Array( this.previous ), 3 ),
				next: new THREE.BufferAttribute( new Float32Array( this.next ), 3 ),
				side: new THREE.BufferAttribute( new Float32Array( this.side ), 1 ),
				width: new THREE.BufferAttribute( new Float32Array( this.width ), 1 ),
				uv: new THREE.BufferAttribute( new Float32Array( this.uvs ), 2 ),
				index: new THREE.BufferAttribute( new Uint16Array( this.indices_array ), 1 ),
				//counters: new THREE.BufferAttribute( new Float32Array( this.counters ), 1 )
				counters: new THREE.BufferAttribute( new Float32Array( this.counters ), 1 ),
				colors: new THREE.BufferAttribute( new Float32Array( this.colors ), 3 ),
				displacement: new THREE.BufferAttribute(new Float32Array( this.displacement ), 1)
			}
		} else {
			this.attributes.position.copyArray(new Float32Array(this.positions));
			this.attributes.position.needsUpdate = true;
			this.attributes.previous.copyArray(new Float32Array(this.previous));
			this.attributes.previous.needsUpdate = true;
			this.attributes.next.copyArray(new Float32Array(this.next));
			this.attributes.next.needsUpdate = true;
			this.attributes.side.copyArray(new Float32Array(this.side));
			this.attributes.side.needsUpdate = true;
			this.attributes.width.copyArray(new Float32Array(this.width));
			this.attributes.width.needsUpdate = true;
			this.attributes.uv.copyArray(new Float32Array(this.uvs));
			this.attributes.uv.needsUpdate = true;
			this.attributes.index.copyArray(new Uint16Array(this.indices_array));
			this.attributes.index.needsUpdate = true;
		}

		this.geometry.addAttribute( 'position', this.attributes.position );
		this.geometry.addAttribute( 'previous', this.attributes.previous );
		this.geometry.addAttribute( 'next', this.attributes.next );
		this.geometry.addAttribute( 'colors', this.attributes.colors );
		this.geometry.addAttribute( 'displacement', this.attributes.displacement)
		this.geometry.addAttribute( 'side', this.attributes.side );
		this.geometry.addAttribute( 'width', this.attributes.width );
		this.geometry.addAttribute( 'uv', this.attributes.uv );
		this.geometry.addAttribute( 'counters', this.attributes.counters );

		this.geometry.setIndex( this.attributes.index );

	}

	function memcpy (src, srcOffset, dst, dstOffset, length) {
		var i

		src = src.subarray || src.slice ? src : src.buffer
		dst = dst.subarray || dst.slice ? dst : dst.buffer

		src = srcOffset ? src.subarray ?
			src.subarray(srcOffset, length && srcOffset + length) :
			src.slice(srcOffset, length && srcOffset + length) : src

		if (dst.set) {
			dst.set(src, dstOffset)
		} else {
			for (i=0; i<src.length; i++) {
				dst[i + dstOffset] = src[i]
			}
		}

		return dst
	}

	/**
	 * Fast method to advance the line by one position.  The oldest position is removed.
	 * @param position
	 */
	MeshLine.prototype.advance = function(position) {

		var positions = this.attributes.position.array;
		var previous = this.attributes.previous.array;
		var next = this.attributes.next.array;
		var l = positions.length;

		// PREVIOUS
		memcpy( positions, 0, previous, 0, l );

		// POSITIONS
		memcpy( positions, 6, positions, 0, l - 6 );

		positions[l - 6] = position.x;
		positions[l - 5] = position.y;
		positions[l - 4] = position.z;
		positions[l - 3] = position.x;
		positions[l - 2] = position.y;
		positions[l - 1] = position.z;

		// NEXT
		memcpy( positions, 6, next, 0, l - 6 );

		next[l - 6]  = position.x;
		next[l - 5]  = position.y;
		next[l - 4]  = position.z;
		next[l - 3]  = position.x;
		next[l - 2]  = position.y;
		next[l - 1]  = position.z;

		this.attributes.position.needsUpdate = true;
		this.attributes.previous.needsUpdate = true;
		this.attributes.next.needsUpdate = true;

	};

	function MeshLineMaterial( parameters ) {

		var vertexShaderSource = [
			'#ifndef PI',
			'#define PI 3.141592653589793',
			'#endif',
			'precision highp float;',
			'',
			'attribute vec3 colors;',
			'attribute vec3 colorsNext;',
			'attribute float displacement;',
			'attribute vec3 position;',
			'',
			'attribute vec3 position0;',
			'attribute vec3 position1;',
			'attribute vec3 position2;',
			'attribute vec3 position3;',
			'',
			'',
			'attribute vec3 positionPrev0;',
			'attribute vec3 positionPrev1;',
			'attribute vec3 positionPrev2;',
			'attribute vec3 positionPrev3;',
			'',
			'attribute vec3 previous;',
			'attribute vec3 next;',
			'attribute float side;',
			'attribute float width;',
			'attribute vec2 uv;',
			'attribute float counters;',
			'',
			'',
			'uniform mat4 projectionMatrix;',
			'uniform mat4 modelViewMatrix;',
			'uniform vec2 resolution;',
			'uniform float lineWidth;',
			'uniform vec3 color;',
			'uniform vec2 mouse;',
			'uniform float opacity;',
			'uniform float near;',
			'uniform float far;',
			'uniform float sizeAttenuation;',
			'uniform float tweenFactor;',
			'uniform float tweenFactorColor;',
			'',
			'varying vec2 vUV;',
			'varying vec4 vColor;',
			'varying float vCounters;',
			'',
			'vec2 fix( vec4 i, float aspect ) {',
			'',
			'    vec2 res = i.xy / i.w;',
			'    res.x *= aspect;',
			'	   vCounters = counters;',
			'    return res;',
			'',
			'}',
			'float dist(vec2 first, vec2 second) {',
			'',
			'    float res = sqrt((second.x - first.x)*(second.x - first.x) + (second.y - first.y)*(second.y - first.y));',
			'    return res;',
			'',
			'}',
			'',
			'float sineInOut(float t) {',
  		'return -0.5 * (cos(PI * t) - 1.0);',
			'}',
			'',
			'void main() {',
			'',
			'    float aspect = resolution.x / resolution.y;',
			'	   float pixelWidthRatio = 1. / (resolution.x * projectionMatrix[0][0]);',
			'',
			//'    vColor = vec4( color, opacity );',
			'			',
			'    vColor = vec4( color*mix(colors, colorsNext, tweenFactorColor), opacity );',
			'    vUV = uv;',

			'',
			'    mat4 m = projectionMatrix * modelViewMatrix;',
			//'		 vec3 tweenPositionBezier = (mt*mt*mt*mt * position0) + (4.0 * mt*mt*mt * t * position1) + (4.0 * mt*mt * t*t * position2) + (4.0 * mt * t*t*t * position3) + (t*t*t*t * position0);',
			'		 vec3 tweenPositionBezier;',
			'		 vec3 tweenPositionPrevBezier;',
			'		 if (tweenFactor <= 0.5) {',
			'		   float t = sineInOut(tweenFactor * 2.0);',
			'		   float mt = 1.0 - t;',
			'		   tweenPositionBezier = mt*mt*position0 + 2.0*mt*t*position1 + t*t*position2;',
			'		   tweenPositionPrevBezier = mt*mt*positionPrev0 + 2.0*mt*t*positionPrev1 + t*t*positionPrev2;',
			'		 } else {',
			'		   float t = sineInOut((tweenFactor - 0.5) * 2.0);',
			'		   float mt = 1.0 - t;',
			'		   tweenPositionBezier = mt*mt*position2 + 2.0*mt*t*position1 + t*t*position0;',
			'		   tweenPositionPrevBezier = mt*mt*positionPrev2 + 2.0*mt*t*positionPrev1 + t*t*positionPrev0;',
			'    }',
			'',
			'    vec2 mouseVector = vec2(mouse.x - tweenPositionBezier.x, mouse.y - tweenPositionBezier.y);',
			'    vec2 mouseNormal = normalize(mouseVector);',
			'		 float distance = dist(mouse, vec2(tweenPositionBezier.x, tweenPositionBezier.y));',
			'		 float logDist = log(distance)/log(10.0);',
			'		 float sqrtDist = sqrt(distance);',
			'		 float cubicDist = 0.05*(distance*distance*distance) - 0.7*(distance*distance) + 2.65*distance + 5.0;',
			'		 if (cubicDist < 5.0) cubicDist = 5.0;',
			//'		 float cubicDist = -0.114167 * (distance*distance*distance) + 0.59 * (distance*distance) - 0.0758333 * distance + 1.0;',
			'    vec3 mouseOffset = vec3( mouseVector.x*(displacement/cubicDist), -1.0*abs(mouseVector.y)*(displacement/cubicDist), 0.0 );',
			'		 tweenPositionBezier += mouseOffset;',
			'		 tweenPositionPrevBezier += mouseOffset;',
			'',
			'    vec4 finalPosition = m * vec4( tweenPositionBezier, 1.0 );',
			'    vec4 prevPos = m * vec4( tweenPositionPrevBezier, 1.0 );',
			'',
			'    vec2 currentP = fix( finalPosition, aspect );',
			'    vec2 prevP = fix( prevPos, aspect );',
			'',
			'	   float pixelWidth = finalPosition.w * pixelWidthRatio;',
			'    float w = 1.8 * pixelWidth * lineWidth * width;',
			'',
			'    if( sizeAttenuation == 1. ) {',
			'        w = 1.8 * lineWidth * width;',
			'    }',
			'',
			'    vec2 dir = normalize( currentP - prevP );',
			'',
			'    vec2 normal = vec2( -dir.y, dir.x );',
			'    normal.x /= aspect;',
			'    normal *= .5 * w;',
			'',
			'    vec4 offset = vec4( normal * side, 0.0, 1.0 );',
			'    finalPosition.xy += offset.xy;',
      '',
			'    gl_Position = finalPosition;',
			'',
			'}' ];

		var fragmentShaderSource = [
			'#extension GL_OES_standard_derivatives : enable',
			'precision mediump float;',
			'',
			'uniform sampler2D map;',
			'uniform sampler2D alphaMap;',
			'uniform float useMap;',
			'uniform float useAlphaMap;',
			'uniform float useDash;',
			'uniform vec2 dashArray;',
			'uniform float visibility;',
			'uniform float alphaTest;',
			'uniform vec2 repeat;',
			'',
			'varying vec2 vUV;',
			'varying vec4 vColor;',
			'varying float vCounters;',
			'',
			'void main() {',
			'',
			'    vec4 c = vColor;',
			'    if( useMap == 1. ) c *= texture2D( map, vUV * repeat );',
			'    if( useAlphaMap == 1. ) c.a *= texture2D( alphaMap, vUV * repeat ).a;',
			'	 if( c.a < alphaTest ) discard;',
			'	 if( useDash == 1. ){',
			'	 	 ',
			'	 }',
			'    gl_FragColor = c;',
			'	 gl_FragColor.a *= step(vCounters,visibility);',
			'}' ];

		function check( v, d ) {
			if( v === undefined ) return d;
			return v;
		}

		THREE.Material.call( this );

		parameters = parameters || {};

		this.lineWidth = check( parameters.lineWidth, 1 );
		this.map = check( parameters.map, null );
		this.useMap = check( parameters.useMap, 0 );
		this.alphaMap = check( parameters.alphaMap, null );
		this.useAlphaMap = check( parameters.useAlphaMap, 0 );
		this.color = check( parameters.color, new THREE.Color( 0xffffff ) );
		this.opacity = check( parameters.opacity, 1 );
		this.resolution = check( parameters.resolution, new THREE.Vector2( 1, 1 ) );
		this.sizeAttenuation = check( parameters.sizeAttenuation, 1 );
		this.near = check( parameters.near, 1 );
		this.far = check( parameters.far, 1 );
		this.dashArray = check( parameters.dashArray, [] );
		this.useDash = ( this.dashArray !== [] ) ? 1 : 0;
		this.visibility = check( parameters.visibility, 1 );
		this.alphaTest = check( parameters.alphaTest, 0 );
		this.repeat = check( parameters.repeat, new THREE.Vector2( 1, 1 ) );
		this.mouse = check( parameters.mouse, new THREE.Vector2(0, 0))
		this.tweenFactor = check( parameters.tweenFactor, 0)
		this.tweenFactorColor = check( parameters.tweenFactorColor, 0)

		var material = new THREE.RawShaderMaterial( {
			uniforms:{
				lineWidth: { type: 'f', value: this.lineWidth },
				map: { type: 't', value: this.map },
				useMap: { type: 'f', value: this.useMap },
				alphaMap: { type: 't', value: this.alphaMap },
				useAlphaMap: { type: 'f', value: this.useAlphaMap },
				color: { type: 'c', value: this.color },
				opacity: { type: 'f', value: this.opacity },
				resolution: { type: 'v2', value: this.resolution },
				sizeAttenuation: { type: 'f', value: this.sizeAttenuation },
				near: { type: 'f', value: this.near },
				far: { type: 'f', value: this.far },
				dashArray: { type: 'v2', value: new THREE.Vector2( this.dashArray[ 0 ], this.dashArray[ 1 ] ) },
				useDash: { type: 'f', value: this.useDash },
				visibility: {type: 'f', value: this.visibility},
				alphaTest: {type: 'f', value: this.alphaTest},
				repeat: { type: 'v2', value: this.repeat },
				mouse: { type: 'v2', value: this.mouse },
				tweenFactor: { type: 'f', value: this.tweenFactor },
				tweenFactorColor: { type: 'f', value: this.tweenFactorColor },
			},
			vertexShader: vertexShaderSource.join( '\r\n' ),
			fragmentShader: fragmentShaderSource.join( '\r\n' )
		});

		delete parameters.lineWidth;
		delete parameters.map;
		delete parameters.useMap;
		delete parameters.alphaMap;
		delete parameters.useAlphaMap;
		delete parameters.color;
		delete parameters.opacity;
		delete parameters.resolution;
		delete parameters.sizeAttenuation;
		delete parameters.near;
		delete parameters.far;
		delete parameters.dashArray;
		delete parameters.visibility;
		delete parameters.alphaTest;
		delete parameters.repeat;
		delete parameters.mouse;
		delete parameters.tweenFactor;
		delete parameters.tweenFactorColor;

		material.type = 'MeshLineMaterial';

		material.setValues( parameters );

		return material;

	};

	MeshLineMaterial.prototype = Object.create( THREE.Material.prototype );
	MeshLineMaterial.prototype.constructor = MeshLineMaterial;

	MeshLineMaterial.prototype.copy = function ( source ) {

		THREE.Material.prototype.copy.call( this, source );

		this.lineWidth = source.lineWidth;
		this.map = source.map;
		this.useMap = source.useMap;
		this.alphaMap = source.alphaMap;
		this.useAlphaMap = source.useAlphaMap;
		this.color.copy( source.color );
		this.opacity = source.opacity;
		this.resolution.copy( source.resolution );
		this.sizeAttenuation = source.sizeAttenuation;
		this.near = source.near;
		this.far = source.far;
		this.dashArray.copy( source.dashArray );
		this.useDash = source.useDash;
		this.visibility = source.visibility;
		this.alphaTest = source.alphaTest;
		this.repeat.copy( source.repeat );

		return this;

	};

	if( typeof exports !== 'undefined' ) {
		if( typeof module !== 'undefined' && module.exports ) {
			exports = module.exports = { MeshLine: MeshLine, MeshLineMaterial: MeshLineMaterial };
		}
		exports.MeshLine = MeshLine;
		exports.MeshLineMaterial = MeshLineMaterial;
	}
	else {
		root.MeshLine = MeshLine;
		root.MeshLineMaterial = MeshLineMaterial;
	}

}).call(this);

