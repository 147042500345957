import Maf from './libs/Maf'
import * as THREE from 'three'
import { MeshLine, MeshLineMaterial } from './libs/THREE.MeshLine'
import TWEEN from '@tweenjs/tween.js'

import data from './wavesData'

window.Waves = function(canvasId, position, canvasWidth, canvasHeight) {
  let {l1min, l2min, l1max, l2max, linesArray, linesArray2, linesArray3, linesArray4} = data

  const canvas = document.getElementById(canvasId)

  this.width = canvasWidth
  this.height = canvasHeight
  this.position = position

  let mouseAnimating = false
  let animationEnabled = 'true'
  let animFrame = null
  let intersectedDot
  const mouse = {}
  let mousePos = {x: 0, y: 0}
  let mouseAnimatePos = null
  const distance = 100
  const vFOV = 60
  const scene = new THREE.Scene()
  const camera = new THREE.PerspectiveCamera(vFOV, this.width / this.height, 0.1, 100000)
  camera.position.set(0, 0, distance)
  const renderer = new THREE.WebGLRenderer({ canvas, antialias: true, alpha: true })
  renderer.setSize(this.width, this.height)
  renderer.setPixelRatio(window.devicePixelRatio)
  camera.lookAt(scene.position)
// var controls = new THREE.OrbitControls( camera, renderer.domElement );

  const raycaster = new THREE.Raycaster()

  let tweenLines
  let tweenColors

  const clock = new THREE.Clock()

  const visibleHeightAtZDepth = (depth, camera) => {
    // compensate for cameras not positioned at z=0
    const cameraOffset = camera.position.z
    if (depth < cameraOffset) depth -= cameraOffset
    else depth += cameraOffset

    // vertical fov in radians
    const vFOV = camera.fov * Math.PI / 180
    // Math.abs to ensure the result is always positive
    return 2 * Math.tan(vFOV / 2) * Math.abs(depth)
  }

  const visibleWidthAtZDepth = (depth, camera) => {
    const height = visibleHeightAtZDepth(depth, camera)
    return height * camera.aspect
  }


  const lines = []
  const resolution = new THREE.Vector2(window.innerWidth, window.innerHeight)
  let strokeTexture

  const Params = function () {
    this.lineWidth = 5
    this.taper = 'parabolic'
    this.strokes = false
    this.sizeAttenuation = false
    this.pointCount = 416
  }

  const params = new Params()

  const colors = [
    new THREE.Color('#00b253'),
    new THREE.Color('#fdde2b'),
    new THREE.Color('#ed412d'),
    new THREE.Color('#e4158a'),
    new THREE.Color('#0093d2'),
    new THREE.Color('#e4158a'),
    new THREE.Color('#ed412d'),
    new THREE.Color('#fdde2b'),
    new THREE.Color('#00b253'),
  ]

  function createCurve(source, i, lineType, displayType, lineNumber, slideNumber) {
    const points = getPoints(source, i, displayType)
    const curvePoints = getCurvePoints(points)
    const geometry = getGeometry(curvePoints)
    geometry.displacement = getDisplacement(curvePoints)
    geometry.colors = getColors(0, params.pointCount)

    return {
      geometry, points, lineType, displayType, source, i,
    }
  }

  function getDisplacement(points) {
    return points.map((point, i) => Math.pow((i - points.length / 2), 3) / Math.pow(points.length, 3) * 25)
  }

  const getPoints = (source, i, displayType) => {
    let curvePoints
    if (this.position === 'right') {
      curvePoints = source[i].map((item, j) => new THREE.Vector3(item.x, item.y, 0))
    } else if (this.position === 'left') {
      curvePoints = source[i].map((item, j) => new THREE.Vector3(-item.x, item.y, 0))
    } else if (this.position === 'top') {
      curvePoints = source[i].map((item, j) => new THREE.Vector3(item.y, item.x, 0))
    } else if (this.position === 'bottom') {
      curvePoints = source[i].map((item, j) => new THREE.Vector3(item.y, -item.x, 0))
    }
    return curvePoints
  }

  function getPointsAnimate(source, i) {
    let curvePoints
    curvePoints = getPoints(source, i)

    return curvePoints
  }

  function getCurvePoints(curvePoints) {
    const curve = new THREE.CatmullRomCurve3(curvePoints)
    curve.curveType = 'centripetal'
    curve.tension = 0.5
    return curve.getPoints(params.pointCount)
  }

  function getGeometry(points) {
    return new THREE.Geometry().setFromPoints(points)
  }

  function getColors(currentColor, count) {
    let c = 0
    const colorsArray = []
    for (let i = 0; i < count + 1; i += 2) {
      const arrayNumberPrev = (colors.length > currentColor ? currentColor : (currentColor - (colors.length - 1)))
      const arrayNumberNext = (colors.length > currentColor + 1 ? currentColor + 1 : (currentColor + 1 - (colors.length - 1)))
      colorsArray[i] = new THREE.Color(
        getTween(colors[arrayNumberPrev].r, colors[arrayNumberNext].r, c, (count / 2) / (colors.length - 1)),
        getTween(colors[arrayNumberPrev].g, colors[arrayNumberNext].g, c, (count / 2) / (colors.length - 1)),
        getTween(colors[arrayNumberPrev].b, colors[arrayNumberNext].b, c, (count / 2) / (colors.length - 1)),
      )
      colorsArray[i + 1] = colorsArray[i]
      if (c === (count / 2) / (colors.length - 1)) {
        currentColor += 1
        c = 0
      }
      c++
    }
    return colorsArray
  }


  function clearLines() {
    const lines = scene.children.filter(item => item.name === 'line')
    lines.forEach((l) => {
      scene.remove(l)
    })
    const dots = scene.children.filter(item => item.name === 'dot')
    dots.forEach((l) => {
      scene.remove(l)
    })
  }

  function makeLine(obj) {
    const {
      geometry, points, lineType, displayType, source, i,
    } = obj
    const g = new MeshLine()
    switch (params.taper) {
      case 'none': g.setGeometry(geometry); break
      case 'linear': g.setGeometry(geometry, p => 1 - p); break
      case 'parabolic': g.setGeometry(geometry, p => 1 * Maf.parabola(p, 0.5)); break
      case 'wavy': g.setGeometry(geometry, p => 2 + Math.sin(50 * p)); break
    }

    makeMesh(g.geometry, lineType, displayType, source, i, g)
  }

  function makeMesh(geometry, lineType, displayType, source, i, g) {
    let lineWidth = params.lineWidth
    if (lineType === 'second') lineWidth -= 1
    if (lineType === 'third') lineWidth -= 2

    let opacity = Maf.randomInRange(0.5, 0.8)
    if (lineType === 'second') Maf.randomInRange(0.3, 0.7)
    if (lineType === 'third') Maf.randomInRange(0.1, 0.3)
    if (displayType === 'double') opacity /= 2

    const geometriesTmp = source.map((item, z) => {
      const points = getPointsAnimate(source, z)
      const curvePoints = getCurvePoints(points)
      const geometry = getGeometry(curvePoints)
      const g = new MeshLine()
      g.setGeometry(geometry, p => 1 * Maf.parabola(p, 0.5))
      return [g.geometry.attributes.position.array, g.geometry.attributes.previous.array]
    })

    // const geometries = []
    // for (let ind1 = 1; ind1 < geometriesTmp.length; ind1++) {
    //   for (let ind2 = 0; ind2 < geometriesTmp[ind1].length; ind2++) {
    //     if (!geometries[ind2]) {
    //       geometries[ind2] = []
    //     }
    //     geometries[ind2].push(geometriesTmp[ind1][ind2])
    //   }
    // }
    // for (let ind2 = 0; ind2 < geometriesTmp[0].length; ind2++) {
    //   if (!geometries[ind2]) {
    //     geometries[ind2] = []
    //   }
    //   geometries[ind2].push(geometriesTmp[0][ind2])
    // }

    geometry.addAttribute( 'position0', new THREE.BufferAttribute(geometriesTmp[0][0], 3))
    geometry.addAttribute( 'position1', new THREE.BufferAttribute(geometriesTmp[1][0], 3))
    geometry.addAttribute( 'position2', new THREE.BufferAttribute(geometriesTmp[2][0], 3))
    geometry.addAttribute( 'position3', new THREE.BufferAttribute(geometriesTmp[3][0], 3))

    geometry.addAttribute( 'positionPrev0', new THREE.BufferAttribute(geometriesTmp[0][1], 3))
    geometry.addAttribute( 'positionPrev1', new THREE.BufferAttribute(geometriesTmp[1][1], 3))
    geometry.addAttribute( 'positionPrev2', new THREE.BufferAttribute(geometriesTmp[2][1], 3))
    geometry.addAttribute( 'positionPrev3', new THREE.BufferAttribute(geometriesTmp[3][1], 3))

    const newColors = getColors(1, params.pointCount)
    const colorsArray = []
    for (let j = 0; j < params.pointCount + 1; j++) {
      colorsArray.push(newColors[j].r, newColors[j].g, newColors[j].b)
      colorsArray.push(newColors[j].r, newColors[j].g, newColors[j].b)
    }

    geometry.addAttribute( 'colorsNext', new THREE.BufferAttribute(new Float32Array(colorsArray), 3))

    const material = new MeshLineMaterial({
      map: strokeTexture,
      vertexColors: THREE.VertexColors,
      useMap: params.strokes,
      color: new THREE.Color(0xffffff),
      dashArray: new THREE.Vector2(10, 5),
      opacity,
      resolution,
      sizeAttenuation: params.sizeAttenuation,
      lineWidth,
      near: camera.near,
      far: camera.far,
      depthWrite: true,
      depthTest: true,
      alphaTest: 0,
      transparent: true,
      mouse: new THREE.Vector2(0, 0),
      side: THREE.DoubleSide,
      tweenFactor: 0,
      tweenFactorColor: 0,
    })

    const mesh = new THREE.Mesh(geometry, material)

    mesh.geometries = geometriesTmp
    mesh.name = 'line'
    mesh.animating = false
    mesh.animatingColor = false
    mesh.userData = {
      source,
      lineType,
      displayType,
      i,
      tmpI: i,
      currentColor: 0,
    }
    mesh.frustumCulled = false
    mesh.origGeometry = g
    scene.add(mesh)
  }

  function init() {
    document.addEventListener('mousemove', updateMousePosition, false)
    onWindowResize()
    createLines()
    const axesHelper = new THREE.AxesHelper(50)
    // scene.add(axesHelper)
    animate()

    const lines = scene.children.filter(item => item.name === 'line' && item.animating === false)
    const linesColor = scene.children.filter(item => item.name === 'line' && item.animatingColor === false)
    shuffleArray(lines)
    lines.forEach((item) => {
      item.animatingColor = true
      animateColor(item)
    })
    const anim = (items) => {
      const item = items[0]
      item.animating = true
      setTimeout(() => {
        animateItem(item)
        if (items.length > 1) {
          anim(items.slice(1))
        }
      }, 0)
    }
    if (animationEnabled === 'true') {
      anim(lines)
    }
  }

  function createLine(source, i, lineType, displayType, lineNumber, slideNumber) {
    makeLine(createCurve(source, i, lineType, displayType, lineNumber, slideNumber))
  }

  function createLines() {
    //createLine(linesArray[0], 0, 'first', 'base', 0, 0)
    linesArray.forEach((item, i) => {
      createLine(item, 0, 'first', 'base', i, 0)
    })

    linesArray2.forEach((item, i) => {
      createLine(item, 0, 'second', 'base', i, 0)
    })

    linesArray3.forEach((item, i) => {
      createLine(item, 0, 'first', 'double', i, 0)
    })

    linesArray4.forEach((item, i) => {
      createLine(item, 0, 'second', 'double', i, 0)
    })
  }

  const onWindowResize = () => {
    camera.aspect = this.width / this.height
    camera.updateProjectionMatrix()

    //renderer.setSize(width, height)
    resolution.set(this.width, this.height)
  }

  // window.addEventListener('resize', function(e) {
  //
  //   clearTimeout(window.resizeTimer);
  //   window.resizeTimer = setTimeout(function() {
  //
  //     onWindowResize()
  //     restart()
  //
  //   }, 250);
  //
  // })
  const tmpVector = new THREE.Vector3()

  function animate() {
    // controls.update();
    TWEEN.update()
    animFrame = requestAnimationFrame(animate)
    if (animationEnabled === 'true' && !mouseAnimating) {
      if (!mouseAnimatePos) {
        mouseAnimatePos = mousePos
      }
      let tweenMouse = new TWEEN.Tween(mouseAnimatePos).to(mousePos, 250)
      tweenMouse.onUpdate((obj) => {
        scene.children.filter(item => item.name === 'line').forEach((item => {
          item.material.uniforms.mouse.value = new THREE.Vector2(obj.x, obj.y)
        }))
      })
      tweenMouse.onComplete((obj) => {
        mouseAnimating = false
      })
      tweenMouse.start()
    }
    render()
  }

  function animateColor(item) {
    tweenColors = new TWEEN.Tween({x: 0}).to({x: 1}, 2500)
    tweenColors.onUpdate((elem) => {
      item.material.uniforms.tweenFactorColor.value = elem.x
    })
    tweenColors.onComplete((obj) => {
      if (item.userData.currentColor === colors.length - 1) {
        item.userData.currentColor = 0
      } else {
        item.userData.currentColor += 1
      }
      item.geometry.attributes.colors.array = item.geometry.attributes.colorsNext.array
      const newColors = getColors(item.userData.currentColor, params.pointCount)
      const colorsArray = []
      for (let j = 0; j < params.pointCount + 1; j++) {
        colorsArray.push(newColors[j].r, newColors[j].g, newColors[j].b)
        colorsArray.push(newColors[j].r, newColors[j].g, newColors[j].b)
      }
      item.geometry.attributes.colorsNext.array = new Float32Array(colorsArray)
      item.material.uniforms.tweenFactorColor.value = 0
      item.geometry.attributes.colors.needsUpdate = true
      item.geometry.attributes.colorsNext.needsUpdate = true
      animateColor(item)
    })
    tweenColors.start()
  }

  // function animateItem(item) {
  //   const randomInterval = {
  //     first: getRandomInt(parseInt(l1min), parseInt(l1max)),
  //     second: getRandomInt(parseInt(l2min), parseInt(l2max)),
  //     third: getRandomInt(20000, 30000),
  //     fourth: getRandomInt(15000, 25000),
  //     fifth: getRandomInt(10000, 20000),
  //   }[item.userData.lineType]
  //   tweenLines = new TWEEN.Tween(item.geometry.attributes.position.array).to(item.geometries, randomInterval)
  //   tweenLines.onUpdate((newArray) => {
  //     const previousPart = newArray.slice(0, newArray.length - 6)
  //     const arrStart = []
  //     arrStart.push(newArray[0], newArray[1], newArray[2])
  //     arrStart.push(newArray[0], newArray[1], newArray[2])
  //     item.geometry.attributes.previous.array = Float32Concat(arrStart, previousPart)
  //     item.geometry.attributes.next.array = newArray
  //     item.geometry.attributes.position.needsUpdate = true
  //     item.geometry.attributes.next.needsUpdate = true
  //     item.geometry.attributes.previous.needsUpdate = true
  //   })
  //   tweenLines.onComplete((obj) => {
  //     animateItem(item)
  //   })
  //   tweenLines.easing(TWEEN.Easing.Linear.None)
  //   tweenLines.interpolation(TWEEN.Interpolation.CatmullRom)
  //   //tweenLines.repeat(10)
  //   tweenLines.start()
  // }

  function animateSlide(item, slide, interval) {
    tweenLines = new TWEEN.Tween({x: 0}).to({x: 1}, interval)
    tweenLines.onUpdate((elem) => {
      item.material.uniforms.tweenFactor.value = elem.x
    })
    tweenLines.onComplete((obj) => {
      animateItem(item)
      item.material.uniforms.tweenFactor.value = 0
    })
    tweenLines.start()
  }

  function animateItem(item) {
    const randomInterval = {
      first: getRandomInt(parseInt(l1min), parseInt(l1max)),
      second: getRandomInt(parseInt(l2min), parseInt(l2max)),
      third: getRandomInt(20000, 30000),
      fourth: getRandomInt(15000, 25000),
      fifth: getRandomInt(10000, 20000),
    }[item.userData.lineType]
    animateSlide(item, 0, randomInterval)
  }


  function render() {
    renderer.render(scene, camera)
  }

  function getTween(b, e, i, tweenParam) {
    return b + ((i / tweenParam) * (e - b))
  }

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }



  function updateMousePosition(event) {
    const rect = canvas.getBoundingClientRect()
    mouse.x = (((event.pageX - rect.left - window.scrollX) / canvas.clientWidth) * 2) - 1
    mouse.y = (-((event.pageY - rect.top - window.scrollY) / canvas.clientHeight) * 2) + 1

    const vector = new THREE.Vector3()

    vector.set(
      mouse.x,
      mouse.y,
      0.5,
    )
    vector.unproject(camera)
    const dir = vector.sub(camera.position).normalize()

    const distance = -camera.position.z / dir.z

    mousePos = camera.position.clone().add(dir.multiplyScalar(distance))

    if (intersectedDot) {
      intersectedDot.geometry.vertices[0] = new THREE.Vector3(mousePos.x, mousePos.y, 0)
      intersectedDot.geometry.verticesNeedUpdate = true
    }
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
  }

  function Float32Concat(first, second) {
    let firstLength = first.length,
      result = new Float32Array(firstLength + second.length)

    result.set(first)
    result.set(second, firstLength)

    return result
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr // for testing
  }

  init()

  this.restart = (position, canvasWidth, canvasHeight) => {
    if (tweenColors) tweenColors.stop()
    if (tweenLines) tweenLines.stop()
    this.width = canvasWidth
    this.height = canvasHeight
    camera.aspect = this.width / this.height
    camera.updateProjectionMatrix()

    renderer.setSize(this.width, this.height)
    resolution.set(this.width, this.height)
    clearLines()
    window.cancelAnimationFrame(animFrame)
    init()
  }
}

